//
// Section
// --------------------------------------------------
@import "variables";

.section {
  margin-bottom: $grid-gutter-width-base;

  &:last-child {
    margin-bottom: 0;
  }
}

.section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section__title {
  text-transform: uppercase;
  font-size: $font-size-h3;
  font-weight: $font-weight-normal;
  margin: 1.5rem 0 1rem 0;
  display: inline-block;
}

.section__link {
  color: $gray-lighter;
  padding-right: .25rem;
  transition: padding-right .1s ease-in-out;

  @include hover-focus-active() {
    text-decoration-color: $gray-lightest;
    padding-right: 0;
  }
}

.section__link__icon {
  font-style: normal;
}