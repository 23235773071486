//
// Base styles
// --------------------------------------------------
@import "variables";
body {
  background: $body-bg url("../img/bg-body.png") repeat-x;
}
hr {
  margin: 1.5rem 0;
  border: 0;
  border-bottom: 1px solid #dee0e3;
  background: url("../img/bg-hr.png") no-repeat right;
  height: 41px;
  display: block;
}