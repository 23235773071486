//
// Navbar
// --------------------------------------------------
@import "variables";
$navbar-link-bg: #fafbfd;
$navbar-width-mobile: 200px;

.navbar {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
  z-index: $zindex-sticky;
}

.navbar__item {
  margin-left: .25rem;

  &:first-child {
    margin-left: 0;
  }
}

.navbar__item__link {
  display: inline-block;
  padding: .5rem 1rem;
  color: $gray-light;
  background-color: $navbar-link-bg;
  box-shadow: inset 0 1px 6px rgba(0,0,0,.1);

  @include hover-focus-active() {
    color: $white;
    background-color: $brand-secondary;
    text-decoration: none;
  }
}

.navbar__item--is-active {
  .navbar__item__link {
    background-color: $brand-primary-dark;
    color: $white;
  }
}

// Simple variation
.navbar--simple {
  font-size: .75rem;
  margin-top: 1.75rem;

  @include media-breakpoint-down(md) {
    justify-content: center;
    margin-bottom: $spacer;
  }

  .navbar__item {
    margin-left: 0;
    border-left: 1px solid lighten($gray-lighter, 7%);

    &:first-child {
      border: none;
    }
  }

  .navbar__item__link {
    background-color: transparent;
    box-shadow: none;
    padding: 0 .5rem;

    @include hover-focus-active() {
      color: $gray;
      text-decoration: underline;
      text-decoration-color: $gray-lighter;
    }
  }
}

// Navbar submenu
.navbar-submenu {
  background-color: $brand-secondary;
  box-shadow: 0 3px 5px rgba(0,0,0,.15);
  list-style: none;
  margin: 1px 0 0 0;
  padding: 0;
  position: absolute;
  z-index: $zindex-dropdown;
  display: none;
}

.navbar-submenu__item__link {
  padding: .5rem 1rem;
  display: block;
  color: $white;

  @include hover-focus-active() {
    color: $white;
    text-decoration: none;
    background-color: $brand-secondary-dark;
  }
}

// navbar open state
.show {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

// Mobil verzió
.navbar--mobile {
  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  @include media-breakpoint-down(sm) {
    flex-flow: column nowrap;
    justify-content: flex-start;
    position: fixed;
    background: $brand-secondary;
    left: 0;
    top: 0;
    width: $navbar-width-mobile;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-$navbar-width-mobile);

    @include transition(transform .2s);

    &.is-open {
      left: 0;
      transform: translateX(0);
    }

    .navbar__item {
      margin: 0;
      display: block;
    }

    .navbar__item__link {
      display: block;
      background-color: transparent;
      color: $white;
      box-shadow: none;

      @include hover-focus-active() {
        color: $white;
        background-color: $brand-secondary-dark;
      }
    }

    .navbar-submenu {
      width: 100%;
      box-shadow: none;
      position: relative;
      margin: 0;
      background-color: transparentize($brand-secondary-dark, .5);
    }
  }
}

