//
// Hamburgers menü
// --------------------------------------------------
@import "variables";

$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 40px !default;
$hamburger-layer-height                    : 4px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : $brand-secondary-dark !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  slider,
) !default;

@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
  @include hover-focus-active() {
    outline: none;
  };

  display: none;

  @include media-breakpoint-down(sm) {
    display: inline-block;
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(0);
    z-index: $zindex-dropdown;
    //background-color: $white;

    @include transition(transform .2s);

    &.is-active {
      transform: translateX(200px);
    }
  }
}