//
// Breadcrumb
// --------------------------------------------------
@import "variables";

.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item > a {
    color: $gray-light;
}