//
// Grid overlay (dev tools)
// --------------------------------------------------

@import "variables";

$column-opacity: .20;
$column-background-colors: (
  sm: hsla(4, 48%, 74%, .25),
  md: hsla(178, 52%, 58%, .25),
  lg: hsla(204, 80%, 72%, .25),
  xl: hsla(286, 51%, 44%, .12),
);
$column-colors: (
  sm: hsla(4, 48%, 74%, .75),
  md: hsla(178, 52%, 58%, .75),
  lg: hsla(204, 80%, 72%, .75),
  xl: hsla(286, 51%, 44%, .75),
);

// Settings
:root {
  --offset: map-get($grid-gutter-widths, xs) / 2;
  --max_width: map-get($container-max-widths, sm) - map-get($grid-gutter-widths, xs);
  --color: map-get($column-colors, sm);
  --columns: 3;
  --gutter: map-get($grid-gutter-widths, xs);
  --baseline: 1.5rem;
  --baseline-shift: 0;
  --background-color: map-get($column-background-colors, sm);
  --media-query: 'extra small (xs)';
}

@include media-breakpoint-up(sm) {
  :root {
    --columns: 6;
    --media-query: 'small (sm)';
  }
}

@include media-breakpoint-up(md) {
  :root {
    --background-color: map-get($column-background-colors, md);
    --color: map-get($column-colors, md);
    --gutter: map-get($grid-gutter-widths, md);
    --media-query: 'medium (md)';
    --max_width: map-get($container-max-widths, md) - map-get($grid-gutter-widths, md);
    //--offset: 0;
  }
}

@include media-breakpoint-up(lg) {
  :root {
    --background-color: map-get($column-background-colors, lg);
    --color: map-get($column-colors, lg);
    --columns: 12;
    --gutter: map-get($grid-gutter-widths, lg);
    --media-query: 'large (lg)';
    --max_width: map-get($container-max-widths, lg) - map-get($grid-gutter-widths, lg);
  }
}

@include media-breakpoint-up(xl) {
  :root {
    --background-color: map-get($column-background-colors, xl);
    --color: map-get($column-colors, xl);
    --columns: 12;
    --gutter: map-get($grid-gutter-widths, xl);
    --media-query: 'extra large (xl)';
    --max_width: map-get($container-max-widths, xl) - map-get($grid-gutter-widths, xl);
  }
}

// Helper variables
:root {
  --repeating-width: calc(100% / var(--columns));
  --column-width: calc((100% / var(--columns)) - var(--gutter));
  --background-width: calc(100% + var(--gutter));
  --background-columns: repeating-linear-gradient(
    to right,
    var(--background-color),
    var(--background-color) var(--column-width),
    transparent var(--column-width),
    transparent var(--repeating-width)
  );
  --background-baseline: repeating-linear-gradient(
    to bottom,
    var(--background-color),
    var(--background-color) 1px,
    transparent 1px,
    transparent var(--baseline)
  );
}

.u-grid-overlay {
  position: relative;
}

.u-grid-overlay::before {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin-right: auto;
  margin-left: auto;
  width: calc(100% - (2 * var(--offset)));
  max-width: var(--max_width);
  min-height: 100vh;
  content: '';
  background-image: var(--background-columns), var(--background-baseline);
  background-size: var(--background-width) 100%;
  background-position: 0 var(--baseline-shift);
  z-index: 1000;
  pointer-events: none;
}

.u-grid-overlay::after {
  content: var(--media-query);
  position: fixed;
  top: 1rem;
  left: 1rem;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  color: var(--color);
}

/* Codepen styling */
/*body {
  height: 400vh;
}*/