//
// Header
// --------------------------------------------------
$header-height: 144px;

.header {
  @include make-container();
  @include make-container-max-widths();

  height: $header-height;
  margin-bottom: 1.5rem;

  @include media-breakpoint-down(md) {
    height: auto;
  }
}

.header__row {
  @include make-row();
}

.header__logo {
  @include make-col-ready();
  @include make-col(3);

  @include media-breakpoint-down(md) {
    @include make-col(12);
  }
}

.header__nav {
  @include make-col-ready();
  @include make-col(9);

  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;

  height: $header-height;


  @include media-breakpoint-down(md) {
    height: auto;
    @include make-col(12);
  }
}