// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Start with assigning color names to specific hex values.
$white:  #fff !default;
$black:  #000 !default;
$red:    #ff551a !default;
$orange: #f0ad4e !default;
$yellow: #ffd500 !default;
$green:  #5cb85c !default;
$blue:   #0275d8 !default;
$teal:   #5bc0de !default;
$pink:   #f67cb7 !default;
$purple: #c371ea !default;

// Create grayscale
$gray-dark:                 #292b2c !default;
$gray:                      #505050 !default;
$gray-light:                #77787b !default;
$gray-lighter:              #c6c6c6 !default;
$gray-lightest:             #fafbfd !default;

// Reassign color vars to semantic color scheme
$brand-primary:             #b2d234 !default;
$brand-primary-dark:        #9fbb2e !default;
$brand-primary-light:       #cee282 !default;
$brand-secondary:           #919386 !default;
$brand-secondary-dark:      #717362 !default;
$brand-success:             $green !default;
$brand-info:                $teal !default;
$brand-warning:             $orange !default;
$brand-danger:              $red !default;
$brand-inverse:             $gray-dark !default;

$color-palette: (
  'primary':    $brand-primary,
  'secondary':  $brand-secondary,
  'red':        $red,
  'orange':     $orange,
  'yellow':     $yellow,
  'green':      $green,
  'blue':       $blue,
  'teal':       $teal,
  'pink':       $pink,
  'purple':     $purple,
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            false !default;
$enable-shadows:            true !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       false !default;

$spacer:   1rem !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Muli', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
//$font-family-serif:      'VerveineW01-Regular', Georgia, "Times New Roman", Times, serif !default;
//$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
//$font-family-base:       $font-family-sans-serif !default;

$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$font-size-h1: 1.75rem !default;
$font-size-h2: 1.5rem !default;
$font-size-h3: 1.5rem !default;
$font-size-h4: 1.25rem !default;
$font-size-h5: 1rem !default;
$font-size-h6: .82rem !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   200 !default;
$headings-line-height:   1.1 !default;
$headings-color:         #748821 !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12 !default;
$grid-gutter-width-base:     30px !default;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
) !default;

// Breadcrumbs

$breadcrumb-padding-y:          .75rem !default;
$breadcrumb-padding-x:          0 !default;
$breadcrumb-item-padding:       .5rem !default;

$breadcrumb-bg:                 $white !default;
$breadcrumb-divider-color:      lighten($brand-secondary, 30%) !default;
$breadcrumb-active-color:       darken($brand-secondary-dark, 20%) !default;
$breadcrumb-divider:            "/" !default;