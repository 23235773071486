//
// Logo
// --------------------------------------------------
@import "variables";

.logo {
  display: block;
  border-top: 6px solid $brand-primary;
  padding-top: 1.75rem;
  text-align: center;
}

.logo__image {
  @include img-fluid();

  @include media-breakpoint-down(md) {
    width: 320px;
  }

  @include media-breakpoint-down(sm) {
    width: 300px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 200px;
    width: 100%;
  }
}