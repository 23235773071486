//
// Overlay
// --------------------------------------------------
@import "variables";

.overlay {
  position: fixed;
  z-index: $zindex-dropdown;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: rgba(0,0,0, .2);
  visibility: hidden;
  opacity: 0;
  backface-visibility: hidden;

  @include transition(opacity .3s 0s, visibility 0s .3s, transform .3s 0s);

  &.is-visible {
    opacity: 1;
    visibility: visible;
    @include transition(opacity .3s 0s, visibility 0s 0s, transform .3s 0s);

    @include media-breakpoint-up(md) {
      opacity: 0;
      visibility: hidden;
    }
  }

  /*@include MQ(maxL) {
      &.is-visible {
          @include transform(translateX(-$nav-width-S));
      }
      .nav-on-left &.is-visible {
          @include transform(translateX($nav-width-S));
      }
      &.is-visible.search-is-visible, .nav-on-left &.is-visible.search-is-visible {
          @include transform(translateX(0));
      }
  }*/

}