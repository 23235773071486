//
// Footer
// --------------------------------------------------
@import "variables";

.footer {
  @include make-container();
  @include make-container-max-widths();
  font-size: .85rem;
  margin-top: 1.5rem;
  color: $gray-light;
  min-height: 4.5rem;
}

.footer__row {
  @include make-row();
}

.footer__menu {
  @include make-col-ready();
  @include make-col(6);
  list-style: none;

  @include media-breakpoint-down(md) {
    @include make-col(12);
    text-align: center;
  }
}

.footer__menu__item {
  display: inline-block;
  margin-right: 1rem;
}

.footer__menu__item__link {
  color: $gray-light;

  @include hover-focus-active() {
    color: $gray;
    text-decoration-color: $gray-lighter;
  }
}

.footer__address {
  @include make-col-ready();
  @include make-col(6);

  text-align: right;

  @include media-breakpoint-down(md) {
    @include make-col(12);
    text-align: center;
  }
}

.footer__copyright {
  color: $gray-lighter;
  font-size: .75rem;
  text-align: center;
}