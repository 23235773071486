@import url('https://fonts.googleapis.com/css?family=Muli:200,400,700&subset=latin-ext');

@font-face {
    font-family: 'HandOfSeanRegular';
    src: url('../fonts/handofsean-webfont.eot');
    src: url('../fonts/handofsean-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/handofsean-webfont.woff') format('woff'),
         url('../fonts/handofsean-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

strong, b {
  font-weight: 700 !important;
}