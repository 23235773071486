//
// Badge
// --------------------------------------------------
@import "variables";
.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  transform: rotate(-15deg);
  font-family: 'HandOfSeanRegular', $font-family-base;

  strong {
    font-weight: normal !important;
  }
}

.badge__icon {
  display: block;
  width: 200px;
  height: 200px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.badge__text {
  z-index: 10;
  width: 170px;
  text-align: center;
  color: #fff;
  font-style: normal;
  font-size: 1.25rem;
}

.badge .badge__icon {
  fill: $brand-primary;
}

// badge color variations
@each $color in map-keys($color-palette) {
  .badge--#{$color} .badge__icon {
    fill: map_get($color-palette, $color);
  }
}
