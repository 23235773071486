//
// Page
// --------------------------------------------------
.page--login, .page--error {
  height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    //width: 200px;
    margin-bottom: 3rem;
    border: none;

    img {
      max-width: 250px;
    }
  }
}

.page--error {
  //@include gradient-radial($body-bg, rgba($brand-danger, .2));
}


.page-title {
  margin-bottom: 1.1rem;
  text-transform: uppercase;
}

.page-hero {
  @include img-fluid();
  margin-bottom: 1.5rem;
}

// Page overrides
.page {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul > li {
    margin-bottom: .5rem;
    padding-left: 1.5em;
    position: relative;

    &::before {
      position: absolute;
      content: "• ";
      color: $brand-primary-dark;
      left: .5rem;
      font-size: .75rem;
      top: .25rem;
    }
  }
}

