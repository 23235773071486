//
// Error
// --------------------------------------------------
@import "variables";

.error {
  text-align: center;
  color: $brand-danger;
}

.error__code {
  font-size: 8rem;
  font-weight: $font-weight-bold;
  text-shadow: 3px 4px 7px rgba(0,0,0,.1);
  margin: 0;
  line-height: 1;
}

.error__message {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: $gray-light;
}