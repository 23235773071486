//
// Cards
// --------------------------------------------------
@import "variables";

.card {
  @include media-breakpoint-down(sm) {
    margin-bottom: $spacer;
  }
}
.card__title {
  text-transform: uppercase;
  font-size: $font-size-h4;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-h5;
  }
}
.card__link {
  display: block;
  position: relative;
  text-decoration: none;

  @include hover-focus-active() {
    text-decoration: none;
  }
}
.card__image {
  width: 100%;
  height: auto;
}
.card__caption {
  margin: 0;
}

.card--cover {
  .card__caption {
    position: absolute;
    background-color: rgba($brand-secondary, .85);
    color: #fff;
    bottom: 0;
    width: 100%;
    padding: .5rem;
    transition: padding .15s ease-in-out;
  }

  .card__link:hover .card__caption {
    background-color: $brand-secondary;
    padding-bottom: 1rem;
  }
}