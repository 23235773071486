//
// LAyout with right sidebar
// --------------------------------------------------

.l-sidebar {
  @include make-row();
}

.l-sidebar__aside {
  @include make-col-ready();
  @include make-col(3);

  @include media-breakpoint-down(md) {
    @include make-col(4);
  }

  @include media-breakpoint-down(sm) {
    @include make-col(12);
    display: none;
  }
}

.l-sidebar__main {
  @include make-col-ready();
  @include make-col(9);

  @include media-breakpoint-down(md) {
    @include make-col(8);
  }

  @include media-breakpoint-down(sm) {
    @include make-col(12);
  }
}