//
// Slider theme
// --------------------------------------------------

.slider {
  width: 100%;
  display: block;
  //background-color: yellow;
  max-height: 550px;
  margin-bottom: $grid-gutter-width-base*2;
}

.slide {
  display: block;
  //width: 100%;
  max-height: 550px;
  position: relative;
}

.slide__image {
  @include img-fluid();
}

.slide__caption {
  position: absolute;
  max-width: 50%;
  bottom: 2rem;
  right: 2rem;
  background-color: rgba(0,0,0,.75);
  color: #fff;
  padding: 1rem;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}


// slide caption positions
.slide__caption--top-left {
  top: 2rem;
  right: inherit;
  bottom: inherit;
  left: 2rem;
}

.slide__caption--top-right {
  top: 2rem;
  right: 2rem;
  bottom: inherit;
  left: inherit;
}

.slide__caption--bottom-left {
  top: inherit;
  right: inherit;
  bottom: 2rem;
  left: 2rem;
}

.slide__caption--bottom-right {
  top: inherit;
  right: 2rem;
  bottom: 2rem;
  left: inherit;
}

.slide__caption__title {
  color: $brand-primary;
  font-size: $font-size-h4;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
}

.slide__caption__lead {
  margin: 0;
}

// badge color variations
@each $color in map-keys($color-palette) {
  .slide__caption--#{$color} .slide__caption__title {
    color: map_get($color-palette, $color);
  }
}

// slide badges
.slide__badge {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 2rem;
  left: 2rem;

  @include media-breakpoint-down(sm) {
    zoom: .75;
  }
}

.slide__badge--top-left {
  top: 2rem;
  left: 2rem;
  bottom: inherit;
  right: inherit;
}

.slide__badge--top-right {
  top: 2rem;
  left: inherit;
  bottom: inherit;
  right: 2rem;
}

.slide__badge--bottom-left {
  top: inherit;
  left: 2rem;
  bottom: 2rem;
  right: inherit;
}

.slide__badge--bottom-right {
  top: inherit;
  left: inherit;
  bottom: 2rem;
  right: 2rem;
}