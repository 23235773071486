//
// Sidebar
// --------------------------------------------------
@import "variables";
.sidebar {
  background: $brand-secondary url("../img/bg-aside-bottom.jpg") no-repeat bottom left;
  background-size: contain;
  height: 100%;
  //min-height: 70vh;
}

.sidebar__title {
  background-color: $brand-primary;
  color: $white;
  font-size: 1rem;
  font-weight: 700;
  text-shadow: 0 1px 2px rgba(0,0,0,0.2);
  text-transform: uppercase;
  padding: 1rem $grid-gutter-width-base/2;
  margin-bottom: 0;
}

.sidebar__nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar__nav__divider {
  border-top: 1px solid transparentize($white, .85);
}

.sidebar__nav__item__link {
  color: $white;
  display: block;
  padding: .5rem $grid-gutter-width-base/2;

  @include hover-focus-active() {
    background-color: $brand-secondary-dark;
    color: $white;
    text-decoration: none;
  }
}

.sidebar__nav__item--is-active {
  .sidebar__nav__item__link {
    background-color: lighten($brand-secondary-dark, 5%);
    color: $white;
    text-decoration: none;
  }
}

// submenu
.sidebar__nav__submenu {
  background-color: transparentize(lighten($brand-secondary-dark, 5%), .5);
  box-shadow: inset 0 -5px 10px rgba(0,0,0,.05);
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar__nav__submenu__item__link {
  display: block;
  padding: .35rem $grid-gutter-width-base/2 .35rem 1.5rem;
  font-size: $font-size-sm;
  color: transparentize(white, .2);

  @include hover-focus-active() {
    color: white;
    background-color: $brand-secondary-dark;
    text-decoration: none;
  }
}

.sidebar__nav__submenu__item--is-active {
  .sidebar__nav__submenu__item__link {
    color: white;
    background-color: $brand-secondary-dark;
  }
}
