//
// Utils
// --------------------------------------------------

// sitebuild utils
.u-bg-red { background: rgba($red, .1); }
.u-bg-green { background: rgba($green, .1); }
.u-bg-blue { background: rgba($blue, .1); }


.u-opacity-90 {
  opacity: .9;

}.u-opacity-50 {
  opacity: .5;
}


.u-text-justify {
  text-align: justify;
}
