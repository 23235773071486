//
// News
// --------------------------------------------------
@import "variables";

$post-background-color-hover: $brand-primary-light;//transparentize($brand-primary, .75);

.post-item {
  background-color: #fff;

  .media-body {
    padding: .25rem;
  }

  color: inherit;
  transition: background .2s linear;
  position: relative;

  @include hover-focus-active() {
    text-decoration: none;
    color: inherit;
    background-color: $post-background-color-hover;

    &:after {
      content: "";
      width: 148px;
      height: 96px;
      background: transparent url("../img/sprites.png") no-repeat -240px 0;
      display: block;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 10;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: $spacer;
  }
}

.post-item__image {
  width: 35%;
  height: auto;
  margin-right: $spacer;
  border: 4px solid $post-background-color-hover;
}

.post-item__title {
  font-size: 1.25rem;
}

.post-item__date {
  color: $gray-light;
  font-size: $font-size-sm;
}

.post-item__lead {
  margin: 0;
}

// posts list
.posts {
  .post-item {
    margin-bottom: $spacer;

    //&:last-child {
    //  margin-bottom: 0;
    //}
  }

  .post-item__image {
    width: 20%;
  }
}

// services items
.post-item--service {
  background-color: $gray-lightest;
  //min-height: 100px;
  //height: 90px;
  //overflow: hidden;

  &:after {
    content: "";
    width: 148px;
    height: 96px;
    background: transparent url("../img/sprites.png") no-repeat -240px 0;
    display: block;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 10;
  }

  .post-item__image {
    width: 150px;
    height: auto;
    border: none;
    border-right: 4px solid $post-background-color-hover;
  }

  .post-item__lead {
    font-size: $font-size-sm;
  }
}

// MD felbontáson a képet felülre tesszük
@include media-breakpoint-only(md) {
  .post-item {
    flex-direction: column;
  }

  .post-item__image {
    width: 100%;
    height: auto;
  }

  .posts {
    .post-item {
      flex-direction: row;
    }
  }
}

@include media-breakpoint-between(sm, md) {
  .post-item--service {
    flex-direction: column;
    .post-item__image {
      width: 100%;
      height: auto;
      border-right: none;
      border-bottom: 4px solid $post-background-color-hover;
    }
  }
}